<template>
    <div>
      <h1>Page Not Found</h1>
      <p>The page you are looking for does not exist.</p>
    </div>
  </template>

  <script>
  export default {
    name: 'NotFoundComponent',
  };
  </script>

  <style scoped>
  h1 {
    color: red;
  }
  </style>
