import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    jwt: null,
    userRoles: [],
    isAuthenticated: false,
    sessionExpired: false,
    error: null,
  }),
  actions: {
    setError(error) {
      this.error = error;
    },
    async login(username, password) {
      try {
        const response = await axios.post(`${import.meta.env.VITE_BACKEND_URL}/login`, { username, password });

        // Update state with JWT and roles
        this.jwt = response.data.token;
        this.userRoles = response.data.roles;
        this.isAuthenticated = true;
        this.error = null;

        // Store JWT in localStorage for persistence
        localStorage.setItem('jwt-token', response.data.token);
      } catch (error) {
        // Handle error
        this.error = error.response ? error.response.data : error.message;
        this.isAuthenticated = false;
      }
    },
    setSessionExpired(expired) {
      this.sessionExpired = expired;
    },
    checkAuth() {
      const token = localStorage.getItem('jwt-token');
      if (token) {
        // FIXME: Perform validation of token if necessary
        // For now, just set isAuthenticated to true
        this.jwt = token;
        this.isAuthenticated = true;
        this.sessionExpired = false;
      }else{
        this.isAuthenticated = false;
        this.sessionExpired = true;
      }
    },
    logout() {
      // Clear JWT from local storage and state
      localStorage.removeItem('jwt-token');
      this.jwt = null;
      this.userRoles = [];
      this.isAuthenticated = false;
      // this.sessionExpired = true;
    },
  },
});
