<template>
  <div class="container">
    <header>

      <div class="row" @click="redirectToHome" style="cursor: pointer;">
        <div class="col-lg-8">
          <pre class="logo">
       _  ___       ____
 _ __ | |/ _ \ _ __/ ___|  ___  ___
| '_ \| | | | | '_ \___ \ / _ \/ __|
| |_) | | |_| | |_) |__) |  __/ (__
| .__/|_|\___/| .__/____/ \___|\___|
|_|           |_| <span class="slogan">Security with Clarity</span>
          </pre>
        </div>
      </div>
      <hr>
    </header>
  </div>

  <div class="container">
    <main>
      <router-view :key="$route.fullPath"></router-view>
    </main>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const search_input = ref('');
const router = useRouter(); // get the router instance

const redirectToHome = () => {
  router.push('/');
};

</script>


<style>
.clickable {
  cursor: pointer;
}
</style>
