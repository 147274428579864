<template>
    <div>
        <!-- Display total vulnerabilities -->
        <div>
            <span class="big-num">{{ summary.vul_count }}</span> <span>CVE</span>
            <span v-if="showSecrets && summary.secrets_count > 0">
                <span class="ms-4 big-num">{{ summary.secrets_count }}</span> <span>Secrets</span>
            </span>
        </div>
        <!-- Severity badges displayed inline -->
        <div>
            <span v-for="severity in severityTypes" :key="severity"
                :class="getSeverityClass(severity, summary['vul_' + severity])" data-bs-toggle="tooltip"
                data-placement="top" :title="severity.charAt(0).toUpperCase() + severity.slice(1)">
                {{ summary['vul_' + severity] }}
            </span>
            <span v-if="showSecrets" class="ms-1" :class="getSecretClass(summary.secrets_count)" data-bs-toggle="tooltip"
                data-placement="top" :title="'Secrets'">
                {{ summary.secrets_count }} <i class="bi bi-key"></i>
            </span>
            <span v-if="showExploits" :class="getSecretClass(summary.exploits_count)" data-bs-toggle="tooltip"
                data-placement="top" :title="'Exploits'">
                {{ summary.exploits_count }} <i class="bi bi-radioactive"></i>
            </span>
        </div>
    </div>
</template>

<script>
import { Tooltip } from 'bootstrap';

export default {
    mounted() {
        this.initTooltips();
    },
    watch: {
        items() {
            this.$nextTick(() => {
                this.initTooltips();
            });
        }
    },
    beforeDestroy() {
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach(tooltipTriggerEl => {
            let tooltipInstance = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
            if (tooltipInstance) {
                tooltipInstance.dispose();
            }
        });
    },
    props: {
        summary: {
            type: Object,
            required: true,
        },
        showSecrets: {
            type: Boolean,
            default: true
        },
        showExploits: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        severityTypes() {
            return ['critical', 'high', 'medium', 'low', 'unknown'];
        }
    },
    methods: {
        initTooltips() {
            const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
            tooltipTriggerList.forEach(tooltipTriggerEl => {
                new Tooltip(tooltipTriggerEl);
            });
        },
        getSeverityClass(severity, count) {
            // Apply .badge.bg-none if severity count is 0, otherwise use the standard class
            return count === 0 ? `custom-badge badge bg-none` : `custom-badge badge bg-${severity}`;
        },
        getSecretClass(secretsCount) {
            // Apply .badge.bg-none if secrets count is 0, otherwise use bg-critical
            return secretsCount === 0 ? 'custom-badge badge bg-none' : 'custom-badge badge bg-critical';
        }
    },
};
</script>
