import { createApp } from 'vue'
import { createPinia } from 'pinia'

import * as Sentry from "@sentry/vue";

import App from './App.vue'
import router from './router/index.js'

import { useAuthStore } from './stores/auth.js';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

// TODO:  Move those to respective vue pages or the the Auth section only
import SecuritySummary from './components/SecuritySummaryBadges.vue';
import VulnerabilitiesSummary from './components/VulnerabilitiesSummary.vue';

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
// import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons'
import { faCopy, faFolderOpen, faIdBadge, faKeyboard, faSquareCheck, faUser, faCircleQuestion as farCircleQuestion } from '@fortawesome/free-regular-svg-icons'
import {
    faGithub, faGolang, faJira, faPython, faWindows, faDocker, faWhatsapp, faTwitter, faFacebook, faLinkedin,
    faLinkedinIn, faTelegramPlane, faMicrosoft, faSlack, faGoogle, faLinux, faNodeJs, faDebian, faUbuntu, faRedhat, faJava,
    faCentos,
    faAws,
    faAmazon
} from '@fortawesome/free-brands-svg-icons';
import {
    faBolt, faBox, faBug, faBuilding, faBullhorn, faCircleInfo, faCircleRadiation, faCogs, faDatabase, faDoorOpen,
    faDownload, faEnvelope, faEnvelopeOpenText, faExchangeAlt, faFileAlt, faKey, faLayerGroup, faLink, faListAlt,
    faNetworkWired, faPlay, faQuestionCircle, faRadiation, faScroll, faShieldHalved, faSliders, faTag, faTerminal,
    faTimesCircle, faTools, faUnlink, faUpload
} from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(farCircleQuestion, faDatabase, faBug, faShieldHalved, faListAlt, faIdBadge, faTools,
    faBox, faKey, faUnlink, faTimesCircle, faFileAlt, faBuilding, faExchangeAlt, faBolt,
    faEnvelope, faBullhorn, faQuestionCircle, faSquareCheck, faRadiation, faJira, faWindows, faLink,
    faScroll, faBox);

/* OS */
library.add(faLinux, faDebian, faUbuntu, faRedhat, faCentos, faAmazon)

/* Languages */
library.add(faPython, faGolang, faNodeJs, faJava)

/* Brand */
library.add(faGithub, faWhatsapp, faTwitter, faTelegramPlane, faMicrosoft, faGoogle);

/* Simple Scan */
library.add(faTerminal, faTag, faKeyboard, faDocker, faFolderOpen, faCogs, faDoorOpen, faUser, faCopy, faNetworkWired, faSliders, faCircleInfo,
    faLayerGroup, faDownload, faPlay, faUpload, faDatabase);


import './assets/main.css'

const app = createApp(App)

Sentry.init({
    app,
    dsn: "https://4e38c341e75669c454f8dec89aa20291@o4507690472177664.ingest.de.sentry.io/4507690479255632",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/plopsec\.com/],
    tracePropagationTargets: [/^https:\/\/plopsec\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(createPinia())
app.use(router)

const authStore = useAuthStore();
authStore.checkAuth(); // Check authentication status on app start

app.component('font-awesome-icon', FontAwesomeIcon)
app.component('SecuritySummary', SecuritySummary);
app.component('VulnerabilitiesSummary', VulnerabilitiesSummary);

app.mount('#app')
