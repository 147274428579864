import { createRouter, createWebHistory } from 'vue-router';
import { jwtDecode } from 'jwt-decode';
import { useAuthStore } from '../stores/auth';

import AuthLayout from '../layouts/AuthLayout.vue';
import PublicLayout from '../layouts/PublicLayout.vue';
import NotFoundComponent from '../views/NotFoundComponent.vue'; // Import the NotFoundComponent

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: PublicLayout,
            children: [
                { path: '', component: () => import('../views/Welcome.vue'), meta: { requiresAuth: false } },
                // { path: 'commands', component: () => import('../views/Commands.vue'), meta: { requiresAuth: false } },
                { path: 'cve/:id', name: 'cve', component: () => import('../views/Cve.vue'), meta: { requiresAuth: false } },
                { path: 'scan/:uuid', component: () => import('../views/SimpleScan.vue'), meta: { requiresAuth: false } },
                // { path: 'scan2/:uuid', component: () => import('../views/SimpleScan2.vue'), meta: { requiresAuth: false } },
                // { path: 'login', name: 'login', component: () => import('../views/Login.vue'), meta: { requiresAuth: false } },
                // { path: 'sboms', component: () => import('../views/Sboms.vue'), meta: { requiresAuth: false } },
                // { path: 'sbom/:sbomId', component: () => import('../views/Sbom.vue'), meta: { requiresAuth: false } },
            ],
        },
        // {
        //     path: '/',
        //     component: AuthLayout,
        //     children: [
        //         { path: 'dashboard', component: () => import('../views/Dashboard.vue'), meta: { requiresAuth: true } },
        //         { path: 'image/:imageId', component: () => import('../views/Image.vue'), meta: { requiresAuth: true } },
        //         { path: 'images', component: () => import('../views/Images.vue'), meta: { requiresAuth: true } },
        //         { path: 'scans', component: () => import('../views/Scans.vue'), meta: { requiresAuth: true } },
        //         { path: 'settings', component: () => import('../views/Settings.vue'), meta: { requiresAuth: true } },
        //         { path: 'status', component: () => import('../views/Status.vue'), meta: { requiresAuth: true } },

        //     ],
        // },
        // Catch-all route for 404 errors
        {
            path: '/:catchAll(.*)',
            component: NotFoundComponent,
            name: 'NotFound',
        },
        // {
        //     // 404
        //     // ---
        //     // Your server will no longer report 404 errors as all not-found paths now serve up your index.html file.
        //     // To get around the issue, you should implement a catch-all route within your Vue app to show a 404 page:
        //     // src: https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
        //     path: '/:catchAll(.*)',
        //     component: NotFoundComponent,
        //     name: 'NotFound'
        // }
    ]
});

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore(); // Get the instance of the auth store

    // Check for a valid JWT token if the route requires authentication
    if (to.meta.requiresAuth) {
        const jwtToken = authStore.jwt; // Assuming your JWT token is stored in the authStore

        if (jwtToken) {
            try {
                const decodedToken = jwtDecode(jwtToken);
                const isExpired = decodedToken.exp < Date.now() / 1000;

                if (isExpired) {
                    // Token is expired, redirect to login
                    next({ path: '/login', query: { redirect: to.fullPath } });
                } else {
                    // Token is valid, proceed
                    next();
                }
            } catch (error) {
                // Token is invalid, redirect to login
                next({ path: '/login', query: { redirect: to.fullPath } });
            }
        } else if (to.name !== 'login') {
            // No token and not going to login, redirect to login
            next({ path: '/login', query: { redirect: to.fullPath } });
        } else {
            // Proceed if going to login
            console.log("here 1!")
            next();
        }
    } else {
        // If the route does not require authentication, just proceed
        next();
    }
});

export default router;
