<template>
  <div>
    <!-- Display total vulnerabilities -->
    <div class="big-num">
      {{ summary.vul_count }} <span class="">CVE</span>
    </div>

    <!-- Display badges for each severity type -->
    <span v-for="severity in severityTypes" :key="severity"
          :class="getSeverityClass(severity, summary['vul_'+severity])"
          data-bs-toggle="tooltip" data-placement="top"
          :title="severity.charAt(0).toUpperCase() + severity.slice(1)">
      {{ summary['vul_'+severity] }}
    </span>

    <!-- Display secrets count with key icon, applying .bg-none if count is 0 -->
    <span :class="['custom-badge badge', summary.secrets_count === 0 ? 'bg-none' : 'bg-critical']"
          data-bs-toggle="tooltip" data-placement="top"
          title="Secrets found">
          {{ summary.secrets_count }} <i class="bi bi-key-fill"></i>
    </span>

    <!-- Display isExploitable with code icon, applying .bg-none if false -->
    <span :class="['custom-badge badge', (summary.exploit.severity != null) ? `bg-${summary.exploit.severity}` : 'bg-none']"
      data-bs-toggle="tooltip" data-placement="top"
      title="Exploit found">
          {{ summary.exploit.isExploitable ? 'Yes' : 'No' }} <i class="bi bi-code-slash"></i>
    </span>
  </div>
</template>

<script>

export default {
  mounted() {
    this.initTooltips();
  },
  watch: {
    items() {
      this.$nextTick(() => {
        this.initTooltips();
      });
    }
  },
  beforeDestroy() {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach(tooltipTriggerEl => {
      let tooltipInstance = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
      if (tooltipInstance) {
        tooltipInstance.dispose();
      }
    });
  },
  props: {
    summary: {
      type: Object,
      required: true,
    },
  },
  computed: {
    severityTypes() {
      return ['critical', 'high', 'medium', 'low', 'unknown'];
    }
  },
  methods: {
    initTooltips() {
      const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
      tooltipTriggerList.forEach(tooltipTriggerEl => {
        new bootstrap.Tooltip(tooltipTriggerEl);
      });
    },
    getSeverityClass(severity, count) {
      // Apply .badge.bg-none if severity count is 0, otherwise use the standard class
      return count === 0 ? `custom-badge badge bg-none` : `custom-badge badge bg-${severity}`;
    },
  },
};
</script>
